// imports react and next
import Link from "next/link";
import React from "react";
import { useSelector } from "react-redux";

// imports utilities
import { isEmpty } from "@/utils/functions";

import StructuredText from "@/common/UI/StructuredText";

const IntroSectionInner = (props) => {
  const {
    title,
    text,
    customStyles = "",
    stickerLabel = "",
    isAttachments = false,
    attachments = [],
  } = props;

  const { labelsList } = useSelector((state) => {
    return {
      labelsList: state?.labelsConfig?.labelsAndConfigData?.buttonsAndLabelsPage,
    };
  });

  return (
    <>
      <div className={`font-futura text-primary ${customStyles}`}>
        <div className={`${!isEmpty(stickerLabel) ? "mb-10" : "my-10"}`}>
          <h1
            className={`font-bold text-3xl leading-[46px] font-futuraBold mb-4 `}
            dangerouslySetInnerHTML={{
              __html: title?.replace(/\n/g, " <br/> "),
            }}
          ></h1>
          {!isEmpty(stickerLabel) && (
            <div className="flex justify-center">
              <h3 className="bg-danger px-6 py-2 text-white text-[20px] leading-[28px] font-bold font-futuraBold">
                {stickerLabel}
              </h3>
            </div>
          )}
        </div>

        <div className="structured-para">
          {typeof text === "string" ? (
            <p className="FuturaLT-book">{text}</p>
          ) : (
            <div className="structure-format">
              <StructuredText data={text} />
            </div>
          )}
        </div>

        {isAttachments && !isEmpty(isAttachments) && (
          <div className="flex mt-6">
            {attachments?.map((attachment, index) => (
              <Link
                href={`${attachment?.url}`}
                key={index}
                className="text-danger font-bold flex gap-[6px] items-center justify-left w-fit"
                target="_blank"
              >
                <span className="font-futuraBold text-xs leading-4 tracking-[1px] uppercase">
                  {labelsList?.download}
                </span>
                <iconify-icon icon="akar-icons:download" width="20" height="20"></iconify-icon>
              </Link>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
export default IntroSectionInner;
